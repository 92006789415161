.dashboard {
  max-width: 980px;
  margin: 40px auto;
}

.dashboard .select-form,
.forms-list .no-forms {
  text-align: center;
}
.dashboard .select-form button {
  margin: 0 15px;
}
.dashboard .forms-list {
  padding: 50px 0;
}

.forms-list .row-header {
  font-weight: bold;
}

.forms-list .form-item {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin: 20px 0;
  padding: 20px 0;
}