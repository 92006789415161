.ant-carousel .slick-slide {
  text-align: center;
  line-height: 60px;
  overflow: hidden;
}
.ant-carousel .slick-dots li button{
  height: 7px !important;
}
.ant-carousel .slick-dots li.slick-active button {
  background: linear-gradient(270deg, rgb(37, 179, 24) 0%, rgb(15, 107, 33) 100%) !important;
}
.hero {
  height: 600px;
}
.hero-relief {
  background: url("../../assets/images/plant-seed-hand.jpg");
  background-position: center;
  background-size: cover;
}
.hero-1 {
  background: url("../../assets/images/front-ground.webp");
  background-position: center;
  background-size: cover;
}
.hero-2 {
  background: url("../../assets/images/front-ground2.webp");
  background-position: center;
  background-size: cover;
}
.hero-3 {
  background: url("../../assets/images/vision.webp");
  background-position: center;
  background-size: cover;
}

.hero .container {
  position: relative;
  top: 180px;
}
.hero .container h1,
.hero .container h2 {
  color: white;
}
@media screen and (max-width: 872px) {
  .hero {
    height: 400px;
  }
  .hero .container {
    top: 140px;
  }
}
@media screen and (min-width: 872px) and (max-width: 992px) {
  .hero {
    height: 500px;
  }
  .hero .container {
    top: 140px;
  }
}

@media screen and (max-width: 869px) {
  .slick-track {
    max-height: 400px;
  }
}