.general-message {
  position: relative;
  top: 25vh;
  margin: 0 auto;
  text-align: center;
  text-align: center;
}

.general-message img {
  margin: 0 auto 20px;
  width: 100px;
}
.general-message p {
  max-width: 600px;
  margin: 0 auto 10px;
}
.general-message a {
  text-decoration: none;
  font-weight: 600;
}
.general-message a:hover {
  text-decoration: none;
  color: #1c8e12;
}

.general-message a:visited {
  color: rgb(95, 95, 95);
}
