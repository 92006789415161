@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700");

body {
  background-color: #fdfdfd;
  color: #262626;
  font-size: 16px;
  line-height: 1.8;
  font-family: "Montserrat", sans-serif;
}

img {
  vertical-align: baseline;
}

.logo {
  margin-right: 20px;
}

.logo img {
  height: 100px;
}

.logo-text {
  position: relative;
  top: 15px;
  font-size: 24px;
  font-weight: 600;
}

.logo-text span {
  color: #1c8e12;
}

@media screen and (max-width: 375px) {
  .logo-text {
    font-size: 16px;
  }
}

@media screen and (max-width: 725px) {
  .logo-text {
    display: none;
  }
}
/*--------------------------------------------------------------
# Links
--------------------------------------------------------------*/
a {
  color: #262626;
}

a:visited {
  color: #757686;
}

a:hover,
a:focus,
a:active {
  color: #262626;
}

a:focus {
  outline: none;
}

a:hover,
a:active {
  outline: none;
  list-style: none;
}

/*--------------------------------------------------------------
# Site Header
--------------------------------------------------------------*/
/*
# Header Bar
--------------------------------*/
#site-header {
  max-height: 170px;
  padding: 0px 0px 14px 0;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  transition: all 0.5s;
  z-index: 997;
}

/*
# Site Branding
--------------------------------*/
.site-branding {
  width: calc(100% - 24px);
  padding: 20px 0;
}

.site-branding a {
  margin-top: 6px;
}

@media screen and (min-width: 992px) {
  .site-branding {
    width: auto;
  }
}

/*
  Hamburger Menu
----------------------------------------*/
.hamburger-menu {
  position: relative;
  width: 24px;
  height: 22px;
  margin: 0 auto;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.hamburger-menu span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #262626;
  border-radius: 10px;
  opacity: 1;
  left: 0;
  transition: 0.25s ease-in-out;
}

.hamburger-menu span:nth-child(1) {
  top: 2px;
}

.hamburger-menu span:nth-child(2),
.hamburger-menu span:nth-child(3) {
  top: 10px;
}

.hamburger-menu span:nth-child(4) {
  top: 18px;
}

.hamburger-menu.open span:nth-child(1) {
  top: 18px;
  width: 0;
  left: 50%;
}

.hamburger-menu.open span:nth-child(2) {
  transform: rotate(45deg);
}

.hamburger-menu.open span:nth-child(3) {
  transform: rotate(-45deg);
}

.hamburger-menu.open span:nth-child(4) {
  top: 18px;
  width: 0;
  left: 50%;
}

/*
# Main Menu
--------------------------------*/
.site-navigation ul {
  position: fixed;
  bottom: 0;
  left: -320px;
  z-index: 9999;
  width: 320px;
  max-width: calc(100% - 80px);
  height: calc(100% - 165px);
  padding: 30px 15px;
  margin: 0;
  background: #fdfdfd;
  list-style: none;
  overflow-x: scroll;
  transition: all 0.35s;
}

.site-navigation.show ul {
  left: 0;
}

.site-navigation ul li {
  margin: 0 16px;
}
.site-navigation ul li a {
  display: block;
  padding: 16px 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  color: #262626;
  text-decoration: none;
  transition: color 0.35s;
}

@media screen and (min-width: 992px) {
  .site-navigation ul {
    position: relative;
    top: auto;
    left: auto;
    width: 100%;
    max-width: 100%;
    height: auto;
    padding: 0;
    overflow: auto;
    background: transparent;
  }

  .site-navigation ul li a {
    padding: 49px 0 30px;
  }
}

.site-navigation ul li a:hover,
.site-navigation ul li.current-menu-item a {
  border-bottom: 3px solid #1f820d;
  color: #1f820d;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.hero-content-overlay {
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
}

.hero-content-wrap img {
  display: block;
  width: 100%;
  height: 100%;
  margin: 0;
  object-fit: cover;
}

.hero-content-overlay h1 {
  margin: 0;
  font-size: 102px;
  font-weight: 600;
  line-height: 1;
}

.hero-content-overlay h4 {
  margin: 0;
  font-size: 46px;
  font-weight: 600;
}

.hero-content-overlay p {
  font-weight: 500;
  line-height: 1.8;
}

.hero-content-overlay .entry-footer a.btn {
  color: #fff;
}

@media screen and (max-width: 1700px) {
  .hero-content-overlay h1 {
    font-size: 72px;
  }

  .hero-content-overlay h4 {
    font-size: 32px;
  }
  .hero-content-overlay p {
    font-size: 14px;
  }
  .hero-slider .pagination-wrap {
    height: 40px;
  }
}

@media screen and (max-width: 992px) {
  .hero-content-overlay h1 {
    font-size: 35px;
  }

  .hero-content-overlay h4 {
    font-size: 22px;
  }

  .hero-content-overlay p {
    display: none;
  }
  .hero-content-overlay .entry-footer a.btn {
    padding: 12px;
    font-size: 12px;
  }
  .hero-content-overlay footer {
    margin-top: 12px !important;
  }
}

@media (max-width: 450px) {
  .entry-header h1 {
    font-size: 1.6em;
  }
  .entry-content h2 {
    font-size: 1.2em;
  }
}

@media (min-width: 451px) and (max-width: 870px) {
  .entry-header h1 {
    font-size: 1.5em;
  }
  .entry-content h2 {
    font-size: 1.2em;
  }
}

/*
# Button
----------------------------------*/
.btn {
  padding: 18px 18px;
  border: 2px solid #262626;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  color: #262626;
  outline: none;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}

.btn.orange-border {
  border-color: #1f820d;
}

.btn.orange-border:hover,
.btn.gradient-bg {
  border-color: #0c6304;
  background: -moz-linear-gradient(
    180deg,
    rgba(26, 138, 15, 1) 0%,
    rgba(12, 78, 25, 1) 100%
  ); /* ff3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0%, rgba(12, 78, 25, 1)),
    color-stop(100%, rgba(1255, 54, 0, 1))
  ); /* safari4+,chrome */
  background: -webkit-linear-gradient(
    180deg,
    rgba(26, 138, 15, 1) 0%,
    rgba(12, 78, 25, 1) 100%
  ); /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(
    180deg,
    rgba(26, 138, 15, 1) 0%,
    rgba(12, 78, 25, 1) 100%
  ); /* opera 11.10+ */
  background: -ms-linear-gradient(
    180deg,
    rgba(26, 138, 15, 1) 0%,
    rgba(12, 78, 25, 1) 100%
  ); /* ie10+ */
  background: linear-gradient(
    270deg,
    rgba(26, 138, 15, 1) 0%,
    rgba(12, 78, 25, 1) 100%
  ); /* w3c */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1f820d', endColorstr='#ff3600',GradientType=1 ); /* ie6-9 */
  color: #fff;
}

/*
# Icon Box
----------------------------------*/
.icon-box {
  padding: 40px;
  border-radius: 24px;
  background: #ecf2f5;
  text-align: center;
  transition: all 0.35s;
}

.icon-box .row {
  justify-content: center;
}
.icon-box:hover,
.icon-box.active {
  border: 0;
  background: -moz-linear-gradient(
    180deg,
    rgba(26, 138, 15, 1) 0%,
    rgba(12, 78, 25, 1) 100%
  ); /* ff3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0%, rgba(12, 78, 25, 1)),
    color-stop(100%, rgba(1255, 54, 0, 1))
  ); /* safari4+,chrome */
  background: -webkit-linear-gradient(
    180deg,
    rgba(26, 138, 15, 1) 0%,
    rgba(12, 78, 25, 1) 100%
  ); /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(
    180deg,
    rgba(26, 138, 15, 1) 0%,
    rgba(12, 78, 25, 1) 100%
  ); /* opera 11.10+ */
  background: -ms-linear-gradient(
    180deg,
    rgba(26, 138, 15, 1) 0%,
    rgba(12, 78, 25, 1) 100%
  ); /* ie10+ */
  background: linear-gradient(
    270deg,
    rgba(26, 138, 15, 1) 0%,
    rgba(12, 78, 25, 1) 100%
  ); /* w3c */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1f820d', endColorstr='#ff3600',GradientType=1 ); /* ie6-9 */
  color: #fff;
}

.icon-box figure img:nth-of-type(1) {
  display: block;
}

.icon-box figure img:nth-of-type(2) {
  display: none;
}

.icon-box:hover figure img:nth-of-type(1),
.icon-box.active figure img:nth-of-type(1) {
  display: none;
}

.icon-box:hover figure img:nth-of-type(2),
.icon-box.active figure img:nth-of-type(2) {
  display: block;
}

.icon-box .entry-title {
  margin-top: 28px;
  font-size: 24px;
  font-weight: 600;
  color: #262626;
}

.icon-box:hover .entry-title,
.icon-box.active .entry-title {
  color: #fff;
}

.cta-links:hover,
.cta-links:focus,
.cta-links:active {
  text-decoration: none;
  color: transparent;
}

.cta-links-div {
  transform: perspective(1px) scale(1) translateZ(0);
  transition: ease-in-out 0.5s;
}
.cta-links-div:hover {
  transform: perspective(1px) scale(1.03) translateZ(0);
  transition: ease-in-out 0.5s;
}
.icon-box .entry-content {
  margin-top: 24px;
}

.icon-box .entry-content p {
  margin: 0;
  font-size: 15px;
  color: #595858;
}

.icon-box:hover .entry-content p,
.icon-box.active .entry-content p {
  color: #fff;
}

@media screen and (max-width: 766px) {
  .icon-box .entry-title {
    font-size: 18px;
  }
  .icon-box .entry-content p {
    font-size: 14px;
  }
}

/*--------------------------------------------------------------
# Homepage
--------------------------------------------------------------*/
/*
# Icon Boxes
--------------------------------*/
.home-page-icon-boxes {
  padding: 96px 0;
}

.our-causes .fa-angle-double-right {
  font-size: 25px;
  font-weight: 600;
}

.our-causes .col-1 {
  text-align: right;
}
/*
# Welcome
--------------------------------*/
.home-page-welcome {
  position: relative;
  padding: 96px 0;
  z-index: 99;
}

.home-page-welcome::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: rgba(21, 21, 21, 0.9);
}

.welcome-content .entry-title {
  position: relative;
  padding-bottom: 24px;
  font-size: 36px;
  font-weight: 600;
  color: #fff;
}

.welcome-content .entry-title::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 64px;
  height: 4px;
  border-radius: 2px;
  background: #1f820d;
}

.welcome-content .entry-content {
  font-size: 15px;
  line-height: 2;
  font-weight: 500;
  color: #b7b7b7;
}

.home-page-welcome img {
  display: block;
  border-radius: 10px;
  width: 100%;
}

.MFC {
  color: #1c8e12;
}

.video {
  margin: 0 auto 30px auto !important;
  align-self: center;
}

.video iframe {
  width: 100%;
  height: 300px;
}

.home-page-limestone p {
  font-weight: 500;
}

@media screen and (max-width: 992px) {
  .home-page-welcome img {
    margin-bottom: 60px;
  }
  .home-page-welcome .entry-footer a.btn {
    padding: 14px;
  }
}
@media screen and (max-width: 650px) {
  .welcome-content .entry-title {
    font-size: 1.6em;
  }
  .welcome-content .entry-content {
    font-size: 14px;
  }
}
/* Who We are 
-------------------*/
.who-wrapper {
  padding-bottom: 130px;
}
.who-wrapper .row {
  margin-top: 80px;
  margin-bottom: 80px;
}

.who-wrapper .entry-title {
  color: black;
}

.who-wrapper .entry-content {
  font-weight: 500;
  color: #4e4949;
}

.who-wrapper img {
  width: 100%;
  border-radius: 10px;
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .who-wrapper img {
    position: relative;
    top: 80px;
  }
}

/* ASSISTANCE OPTIONS*/
.assistance-options {
  text-align: center;
  height: 500px;
}

.assistance-options .main {
  position: absolute;
  left: 0;
  width: 100%;
  padding: 100px 0 120px;
  background: url("../images/lantern.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.assistance-options header {
  max-width: 820px;
  margin: 0 auto;
}
.assistance-options h2 {
  margin-bottom: 30px;
  font-weight: 600;
}
.assistance-options p {
  margin-bottom: 30px;
  font-weight: 500;
}

.assistance-options ul {
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 80%;
  margin: 0 auto;
  padding: 0;
}

.assistance-options ul li {
  font-weight: 500;
  flex-basis: 40%;
  padding: 20px 0;
  margin: 5px;
  background-color: rgb(78 78 78);
  box-shadow: 0 4px 8px 0 rgba(78, 78, 78, 0.2);
  color: white;
}

@media screen and (max-width: 920px) {
  .assistance-options ul {
    flex-direction: column;
    width: 90%;
  }
  .assistance-options {
    height: 750px;
  }
  .assistance-options header {
    width: 95%;
  }
  .assistance-options p,
  .assistance-options li {
    font-size: 1rem;
  }
  .assistance-options h2 {
    font-size: 1.6rem;
  }
}
/*
# Home Causes
--------------------------------*/
.our-causes {
  position: relative;
  padding: 96px 0;
  background: url("../images/peak.webp") no-repeat top;
  background-size: cover;
  z-index: 99;
}

.our-causes::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: rgba(185, 206, 210, 0.8);
}
.our-causes .lead {
  font-weight: 400;
}
@media screen and (max-width: 650px) {
  .our-causes h2 {
    font-size: 1.7em;
  }
  .our-causes .lead {
    font-size: 1em;
  }
  .our-causes h5 {
    font-size: 1.2em;
  }
  .our-causes p {
    font-size: 14px;
  }
}
/*
# Home Milestone
--------------------------------*/

.home-page-limestone {
  padding: 96px 0;
  margin: 0 auto;
}
.home-page-limestone .container {
  max-width: 800px;
}
.home-page-limestone .entry-title {
  font-size: 36px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 40px;
}

/* .counter-box .entry-title {
  margin-top: 16px;
  font-size: 14px;
  color: #595858;
  text-align: center;
}

.counter-box .counter-k {
  color: #595858;
  font-weight: bold;
}
.home-page-limestone {
  padding: 96px 0;
}

.home-page-limestone .section-heading .entry-title {
  padding-bottom: 24px;
  line-height: 1.6;
  position: relative;
  margin-bottom: 36px;
  font-size: 36px;
  font-weight: 600;
}

.home-page-limestone h2::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 64px;
  height: 4px;
  border-radius: 2px;
  background: #1f820d;
} */

.home-page-limestone .section-heading p {
  font-size: 15px;
  color: #595858;
}

@media screen and (max-width: 650px) {
  .home-page-limestone .section-heading .entry-title {
    font-size: 1.6em;
  }

  .home-page-limestone .section-heading p {
    font-size: 14px;
  }
}
/*--------------------------------------------------------------
# About Page
--------------------------------------------------------------*/
.single-page .page-header {
  position: relative;
  z-index: 99;
  padding-top: 100px;
  padding-bottom: 72px;
  background-size: cover !important;
}

.single-page .page-header::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
}

.single-page .page-header h1 {
  position: relative;
  padding-bottom: 24px;
  font-size: 36px;
  font-weight: 600;
  color: #fff;
}

.single-page .page-header h1::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 64px;
  height: 4px;
  border-radius: 2px;
  background: #1f820d;
}

.about-page .page-header {
  background: url(../images/seed.webp) no-repeat center;
}

.about-page .welcome-wrap {
  padding: 96px 0;
}

.about-page .welcome-content .entry-title {
  color: #6d6d6d;
}

.about-page .welcome-content .entry-content {
  color: #929191;
}

@font-face {
  font-family: "quran";
  src: url(../fonts/Amiri-Regular.ttf);
}

.about-page .quran-text {
  font-family: quran;
  font-size: 35px;
  font-weight: 200;
  color: #7a7a7a;
  text-align: right;
  line-height: 60px;
  margin-top: 30px;
}
.about-page .quran-text span {
  position: relative;
  top: 3px;
  left: -10px;
  font-family: "Times New Roman", Times, serif;
  font-weight: bold;
}
.about-page .quran-translation,
.about-page .quran-info {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #929191;
}
.about-page .quran-text span > b {
  position: absolute;
  font-size: 25px;
  left: 6px;
  top: -7px;
}
.features-section {
  background: #fdfdfd;
  padding: 0 0 80px;
}

.features-section img {
  width: 100px;
}
.features-section h2,
.features-section h5 {
  color: #6d6d6d;
}
.features-section p {
  color: #929191;
  font-weight: 400;
}
.features-section i {
  color: #6d6d6d;
}

@media screen and (max-width: 650px) {
  .features-section h2 {
    font-size: 1.6em;
  }
  .about-page .quran-text {
    font-size: 1.5em;
  }
  .about-page .quran-translation,
  .about-page .quran-info {
    font-size: 0.9em;
    font-weight: 500;
  }
  .about-page .quran-text span > b {
    font-size: 0.8em;
    left: 3px;
    top: -15px;
  }
}
/* How Page */
.how-page .sidebar {
  text-align: center;
}
.how-page .textwidget a {
  border-radius: none;
}

.how-page h3 {
  font-size: 1.2em;
  font-weight: 600;
}

/*--------------------------------------------------------------
# Sidebar
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  .sidebar {
    margin-top: 50px;
  }
}

.sidebar h2 {
  font-size: 18px;
  font-weight: 600;
}

/*--------------------------------------------------------------
# Contact Page
--------------------------------------------------------------*/
.contact-form {
  margin-top: 96px;
}
.submitted-message {
  text-align: center;
  font-size: 16px;
  color: #0c6304;
  font-weight: 600;
}
.contact-page .page-header {
  background: url("../../assets/images/seed.webp") no-repeat center;
}
.contact-page-wrap {
  padding: 0 0 96px;
}

.contact-page-wrap .entry-content {
  margin-top: 96px;
}

.contact-page-wrap .entry-content h2 {
  font-size: 24px;
  font-weight: 500;
}

.contact-page-wrap .entry-content p {
  margin-top: 20px;
  font-size: 14px;
  color: #929191;
}

.contact-page-wrap .entry-content ul.contact-social {
  padding: 20px 0 0;
  margin: 0;
  list-style: none;
}

.contact-page-wrap .entry-content ul.contact-social li {
  margin-right: 28px;
  margin-bottom: 8px;
  font-size: 14px;
}

.contact-page-wrap .entry-content ul.contact-social li a {
  color: #1f820d;
}

.contact-page-wrap .entry-content ul.contact-info {
  margin: 32px 0 0;
}

.contact-page-wrap .entry-content ul.contact-info li {
  display: flex;
  align-items: baseline;
  margin-bottom: 15px;
  font-size: 14px;
  color: #9b9a9a;
}

.contact-page-wrap .entry-content ul.contact-info .fa {
  width: 28px;
  margin-right: 10px;
  font-size: 18px;
  color: #1f820d;
  text-align: center;
}

.contact-form .form-inputs {
  padding: 35px 20px 55px;
  background: #0f5918e0;
  border-radius: 10px;
}
/* 
.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form textarea {
  width: 100%;
  padding: 12px 24px;
  border: 1px solid #e0e0e0;
  font-size: 14px;
  line-height: 1;
} */

/* .contact-form input[type="text"]:focus,
.contact-form input[type="email"]:focus,
.contact-form textarea:focus {
  outline: none;
}

.contact-form input[type="text"]::placeholder,
.contact-form input[type="email"]::placeholder,
.contact-form textarea::placeholder {
  color: #929191;
} */

.contact-form span {
  display: block;
  margin-top: 16px;
  text-align: center;
}

.contact-form input[type="submit"] {
  cursor: pointer;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.footer-widgets {
  position: relative;
  padding: 90px 0 50px;
  font-weight: 500;
  color: #929191;
  background: url("../images/foot-bg.webp") no-repeat center;
  background-size: cover;
  z-index: 99;
}

.footer-widgets::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 101%;
  background: rgba(22, 22, 22, 0.92);
}

.footer-widgets p {
  font-size: 14px;
}

.footer-widgets h2 {
  margin-bottom: 40px;
  font-size: 18px;
  color: #fff;
}

.footer-widgets ul {
  padding: 12px 0 0;
  margin: 0;
  list-style: none;
}

.footer-widgets ul li {
  margin-bottom: 8px;
  font-size: 14px;
}

.footer-widgets ul li a {
  color: #a2a1a1;
  text-decoration: none;
}

.footer-widgets ul li a:hover {
  color: #1f820d;
}

.foot-logo img {
  height: 100px;
  margin: 0 auto;
}
.foot-about p {
  font-size: 14px;
  line-height: 2;
  color: #929191;
}

.foot-about ul li {
  margin-right: 28px;
}

.foot-about ul li a {
  color: #fff;
}

.foot-latest-news ul li {
  display: block;
  margin-bottom: 36px;
}

.foot-latest-news ul li h3 {
  font-size: 14px;
}

.foot-latest-news ul li .posted-date {
  font-size: 12px;
  color: #1f820d;
}

.foot-contact ul li {
  display: flex;
  align-items: baseline;
  margin-bottom: 15px;
}

.foot-contact ul li .fa {
  width: 28px;
  margin-right: 10px;
  font-size: 18px;
  text-align: center;
  color: #1f820d;
}

.foot-contact ul li span {
  width: calc(100% - 3px);
}

.copyright {
  text-align: center;
  margin-top: 100px;
  font-weight: 600;
}
/* Application proccess */

.card {
  border: none;
  color: #647585;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
}
.process-list {
  margin-top: 20px;
}

.apply-btn {
  margin-top: 20px;
}

@media screen and (max-width: 505px) {
  .header-message {
    position: fixed;
    background-color: rgb(19, 97, 17);
    text-align: center;
    width: 100%;
    font-size: 0.8em;
    color: rgb(236, 236, 236);
    font-weight: 500;
  }
  .site-header {
    padding-top: 25px;
  }
}

/* APPLICATION PROGRESS */
.ant-progress {
  margin-bottom: 50px;
}
