.forms {
  margin-bottom: 50px;
}
.steps-content {
  height: auto !important;
  margin-top: 30px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  padding: 50px;
}
.steps-action button {
  margin-top: 60px;
}

.steps-content h3 {
  margin: 0 0 50px 15px;
}
/* APPLICATION PROGRESS */
.ant-progress {
  margin-bottom: 50px;
}

