.incentive-section {
  display: flex;
  flex-direction: column;
}
.incentive-section section {
  padding-top: 60px;
  max-width: 80%;
  margin: 0 auto;
}

.incentive-section section h1,
.incentive-section section p,
.volunteer-features .item span {
  color: #6d6d6d;
  font-weight: 500;
}

@media screen and (max-width: 780px) {
  .incentive-section section {
    max-width: 90%;
  }
}
