.application-container {
  /* border: green 2px solid; */
  margin: 70px 0 0 0;
  /* width: 80%; */
}
.application {
  color: rgb(80, 79, 79);
}
.application h3 {
  text-align: center;
  margin-bottom: 35px;
}

.application h5 {
  text-align: center;
  margin: 35px 0;
}
/* .application .field, */
.application .field-group {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid rgb(189, 189, 189);
}
.application .field {
  flex: auto;
}
.application .field::before {
  content: attr(data-name);
  font-weight: 600;
  font-size: 14px;
  margin-right: 25px;
}
.fetchError {
  text-align: center;
  font-weight: 500;
  max-width: 400px;
  font-size: 20px;
  margin: 0 auto;
  position: relative;
  top: 120px;
}
.spinner {
  display: flex !important;
  position: relative !important;
  justify-content: center;
  top: 30vh;
}

/* MEDIA QUERIES */

@media screen and (max-width: 480px) {
  .ant-steps-horizontal.ant-steps-label-horizontal {
    display: -ms-flexbox;
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap;
    align-self: center;
    justify-content: center;
  }
  .ant-steps-item {
    flex: 0 1 !important;
    flex-basis: 20% !important;
  }
}

@media screen and (max-width: 940px) {
  .application .field-group {
    flex-direction: column;
  }
}
